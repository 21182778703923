.image-replacement,
.ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.clearfix,
.cf {
  zoom: 1;
}
.clearfix:before,
.clearfix:after,
.cf:before,
.cf:after {
  content: "";
  display: table;
}
.clearfix:after,
.cf:after {
  clear: both;
}

/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif !important;
  font-style: italic;
}

/******************************************************************

Site Name:
Author:

Stylesheet: Typography and set of icons.

******************************************************************/
/******************************************************************
Site Name:
Author:

Stylesheet: color variables.

******************************************************************/
/******************************************************************
Site Name:
Author:

Stylesheet: Grid Stylesheet

I've seperated the grid so you can swap it out easily. It's
called at the top the style.scss stylesheet.

There are a ton of grid solutions out there. You should definitely
experiment with your own. Here are some recommendations:

http://gridsetapp.com - Love this site. Responsive Grids made easy.
http://susy.oddbird.net/ - Grids using Compass. Very elegant.
http://gridpak.com/ - Create your own responsive grid.
https://github.com/dope/lemonade - Neat lightweight grid.


The grid below is a custom built thingy I modeled a bit after
Gridset. It's VERY basic and probably shouldn't be used on
your client projects. The idea is you learn how to roll your
own grids. It's better in the long run and allows you full control
over your project's layout.

******************************************************************/
.last-col {
  float: right;
  padding-right: 0 !important;
}

/*
Mobile Grid Styles
These are the widths for the mobile grid.
There are four types, but you can add or customize
them however you see fit.
*/
@media (max-width: 767px) {
  .m-all {
    float: left;
    padding-right: 3.75em;
    width: 100%;
    padding-right: 0;
  }

  .m-1of2 {
    float: left;
    padding-right: 3.75em;
    width: 50%;
  }

  .m-1of3 {
    float: left;
    padding-right: 3.75em;
    width: 33.33%;
  }

  .m-2of3 {
    float: left;
    padding-right: 3.75em;
    width: 66.66%;
  }

  .m-1of4 {
    float: left;
    padding-right: 3.75em;
    width: 25%;
  }

  .m-3of4 {
    float: left;
    padding-right: 3.75em;
    width: 75%;
  }
}
/* Portrait tablet to landscape */
@media (min-width: 768px) and (max-width: 1029px) {
  .t-all {
    float: left;
    padding-right: 3.75em;
    width: 100%;
    padding-right: 0;
  }

  .t-1of2 {
    float: left;
    padding-right: 3.75em;
    width: 50%;
  }

  .t-1of3 {
    float: left;
    padding-right: 3.75em;
    width: 33.33%;
  }

  .t-2of3 {
    float: left;
    padding-right: 3.75em;
    width: 66.66%;
  }

  .t-1of4 {
    float: left;
    padding-right: 3.75em;
    width: 25%;
  }

  .t-3of4 {
    float: left;
    padding-right: 3.75em;
    width: 75%;
  }

  .t-1of5 {
    float: left;
    padding-right: 3.75em;
    width: 20%;
  }

  .t-2of5 {
    float: left;
    padding-right: 3.75em;
    width: 40%;
  }

  .t-3of5 {
    float: left;
    padding-right: 3.75em;
    width: 60%;
  }

  .t-4of5 {
    float: left;
    padding-right: 3.75em;
    width: 80%;
  }
}
/* Landscape to small desktop */
@media (min-width: 1030px) {
  .d-all {
    float: left;
    padding-right: 3.75em;
    width: 100%;
    padding-right: 0;
  }

  .d-1of2 {
    float: left;
    padding-right: 3.75em;
    width: 50%;
  }

  .d-1of3 {
    float: left;
    padding-right: 3.75em;
    width: 33.33%;
  }

  .d-2of3 {
    float: left;
    padding-right: 3.75em;
    width: 66.66%;
  }

  .d-1of4 {
    float: left;
    padding-right: 3.75em;
    width: 25%;
  }

  .d-3of4 {
    float: left;
    padding-right: 3.75em;
    width: 75%;
  }

  .d-1of5 {
    float: left;
    padding-right: 3.75em;
    width: 20%;
  }

  .d-2of5 {
    float: left;
    padding-right: 3.75em;
    width: 40%;
  }

  .d-3of5 {
    float: left;
    padding-right: 3.75em;
    width: 60%;
  }

  .d-4of5 {
    float: left;
    padding-right: 3.75em;
    width: 80%;
  }

  .d-1of6 {
    float: left;
    padding-right: 3.75em;
    width: 16.6666666667%;
  }

  .d-1of7 {
    float: left;
    padding-right: 3.75em;
    width: 14.2857142857%;
  }

  .d-2of7 {
    float: left;
    padding-right: 3.75em;
    width: 28.5714286%;
  }

  .d-3of7 {
    float: left;
    padding-right: 3.75em;
    width: 42.8571429%;
  }

  .d-4of7 {
    float: left;
    padding-right: 3.75em;
    width: 57.1428572%;
  }

  .d-5of7 {
    float: left;
    padding-right: 3.75em;
    width: 71.4285715%;
  }

  .d-6of7 {
    float: left;
    padding-right: 3.75em;
    width: 85.7142857%;
  }

  .d-1of8 {
    float: left;
    padding-right: 3.75em;
    width: 12.5%;
  }

  .d-1of9 {
    float: left;
    padding-right: 3.75em;
    width: 11.1111111111%;
  }

  .d-1of10 {
    float: left;
    padding-right: 3.75em;
    width: 10%;
  }

  .d-1of11 {
    float: left;
    padding-right: 3.75em;
    width: 9.09090909091%;
  }

  .d-1of12 {
    float: left;
    padding-right: 3.75em;
    width: 8.33%;
  }
}

.keycolor {
  font-weight: 700;
  color: #7eafeb;
}

.container {
  width: 90%;
}

.block {
  margin-bottom: 1rem;
}

/*
    #logoTop
*/
#logoTop {
  margin-top: 2rem;
}

#logoTop img {
  float: left;
  margin-top: 0.3rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

#logoTop p {
  font-size: 0.8rem;
  line-height: 0.6rem;
  color: #999;
}

#logoBottom p {
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #999;
  text-align: center;
}

#logoBottom {
  margin-bottom: 1rem;
}

#logoBottom img {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.caption {
  text-align: center;
  font-size: 0.8rem;
  color: #999;
}

/*
    sns
*/
.twitter-share-button,
.fb-like {
  float: right;
  margin-right: 4px;
  margin-bottom: 0.4rem;
}

/*
    chart
*/
.dc-chart g.row text {
  fill: #333;
  font-size: 0.8rem;
  paint-order: stroke;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 0.3rem;
}

/* IE10&11 */
_:-ms-input-placeholder,
.dc-chart g.row text {
  fill: #999;
  stroke-width: 0rem !important;
}

div.dc-chart {
  float: none !important;
  text-align: center;
}

/*
    data table
*/
.table tbody tr.info td {
  background-color: #cecece;
}

.table tbody tr.info td:hover {
  background-color: #cecece;
}

#paging {
  float: right;
}

#status {
  font-weight: 700;
  font-size: 0.8rem;
}

/*
    ui
*/
.reset {
  display: inline-block;
  margin: -2px;
  padding: 6px 8px;
  margin-bottom: 0;
  font-size: 0.8em;
  line-height: 20px;
  color: #666;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#fff),
    to(#e6e6e6)
  );
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #ccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#ffe6e6e6',GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  margin: 0.4rem;
}

.reset:hover {
  color: #000000;
  background-image: -moz-linear-gradient(top, #fff, #fefefe);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#fff),
    to(#fefefe)
  );
  background-image: -webkit-linear-gradient(top, #fff, #fefefe);
  background-image: -o-linear-gradient(top, #fff, #fefefe);
  background-image: linear-gradient(to bottom, #fff, #fefefe);
}

.reset:active {
  background-color: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#e6e6e6),
    to(#e6e6e6)
  );
  background-image: -webkit-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: -o-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: linear-gradient(to bottom, #e6e6e6, #e6e6e6);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0),
    0 1px 2px transparent;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0), 0 1px 2px transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0), 0 1px 2px transparent;
}

.h2titlebottom {
  margin-bottom: 10px !important;
}

.titleheight {
  height: 130px;
}

.h2height {
  height: 70px;
}

/* LARGER MOBILE DEVICES */
@media only screen and (min-width: 481px) {
  /******************************************************************
  
  Stylesheet: Resolution between 481px to 767px for LARGER MOBILE DEVICES.
  
  ******************************************************************/
}
/* TABLET & SMALLER LAPTOPS */
@media only screen and (min-width: 768px) {
  /******************************************************************
  
  Stylesheet: Resolution between 768px to 1029px for TABLET & SMALLER LAPTOPS.
  
  ******************************************************************/
  .t-1of2 {
    padding-right: 0.75em;
  }
}
/* LAPTOPS */
@media only screen and (min-width: 1030px) {
  /******************************************************************
  
  Stylesheet: Resolution over 1030px for LAPTOPS.
  
  ******************************************************************/
}
/* RETINA (2x RESOLUTION DEVICES) */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /******************************************************************
  
  Stylesheet: Retina Screens & Devices Stylesheet
  
  ******************************************************************/
}
/* PRINT STYLESHEET */
@media print {
  /******************************************************************
  
  Site Name:
  Author:
  
  Stylesheet: for Print
  
  ******************************************************************/
}
