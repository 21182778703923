import React from "react";
import { Chart } from "../../components/chart";

export const BudgetChart = ({ budget }) => {
  const series = [
    ["request", "要求額"],
    ["original", "当初予算"],
    ["supplementary", "補正予算"],
    ["brought", "前年度から繰越し"],
    ["carried", "翌年度へ繰越し"],
    ["reserve", "予備費等"],
    ["total", "予算計"],
    ["executed", "執行額"],
  ];
  const options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: budget.map(({ year }) => year),
    },
    yAxis: {
      title: {
        text: "百万円",
      },
    },
    series: series.map(([key, name]) => {
      return {
        name,
        data: budget.map((b) => (b[key] == null ? null : +b[key])),
      };
    }),
  };

  return <Chart options={options} />;
};
