import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import Tooltip from "../../components/Tooltip";
import { startYear, endYear, formatCurrency, setLoading } from "../../services";
import { words } from "../../constants";
import { fetchProject } from "../../services/api";
import { BudgetChart } from "./budget-chart";
import { PayeeTable } from "./payee-table";

export class ProjectDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showYear: null,
      payees: [],
      projects: [],
    };
  }

  componentDidMount() {
    setLoading(true);
    const { projectId } = this.props.match.params;
    fetchProject({ projectId }).then((projects) => {
      const showYear = Math.max(...projects.map((p) => p.reportYear));
      const payees = [];
      for (const project of projects) {
        for (const payee of project.payee) {
          payees.push(payee);
        }
      }
      payees.sort((p1, p2) => p2.amount - p1.amount);
      this.setState({
        showYear,
        payees,
        projects,
      });
      setLoading(false);
    });
  }

  render() {
    const { showYear, payees, projects } = this.state;

    if (projects.length === 0) {
      return null;
    }

    const project = projects.find((p) => p.reportYear === showYear);
    const yearBudget = new Map();
    for (const project of projects) {
      for (const budget of project.budget) {
        if (yearBudget.has(budget.year)) {
          const b = yearBudget.get(budget.year);
          for (const key in budget) {
            if (b[key] == null) {
              b[key] = budget[key];
            }
          }
        } else {
          yearBudget.set(budget.year, budget);
        }
      }
    }
    const budget = Array.from(yearBudget.values()).filter(
      ({ year }) =>
        (project.startYear == null || project.startYear <= year) &&
        (project.endYear == null || year <= project.endYear)
    );
    budget.sort((b1, b2) => (b1.year < b2.year ? -1 : 1));

    return (
      <div>
        <Head title={project.projectName} description={project.objective} />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb" style={{ overflowX: "auto" }}>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li>
                  <Link to="/projects">行政事業検索</Link>
                </li>
                <li className="is-active">
                  <a>{project.projectName}</a>
                </li>
              </ul>
            </nav>
            <div className="tabs is-centered">
              <ul>
                {projects.map((project) => {
                  return (
                    <li
                      key={project.reportYear}
                      className={
                        project.reportYear === showYear ? "is-active" : ""
                      }
                    >
                      <a
                        onClick={() => {
                          this.setState({
                            showYear: project.reportYear,
                          });
                        }}
                      >
                        {project.reportYear}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <h3 className="title">{project.projectName}</h3>
              <div className="tags">
                {project.categories.map((category) => {
                  return (
                    <Link
                      key={category}
                      to={`/categories/${category}`}
                      className="tag is-info"
                    >
                      {category}
                    </Link>
                  );
                })}
              </div>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">府省庁:</span>{" "}
                <Link to={`/ministries/${project.ministry}`}>
                  {project.ministry}
                </Link>
              </p>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">事業番号:</span>{" "}
                {project.projectNumber1 && `${project.projectNumber1}-`}
                {project.projectNumber2}
                {project.projectNumber3 && `-${project.projectNumber3}`}
              </p>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">担当部局:</span>{" "}
                {project.bureau} {project.department}
              </p>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">事業期間:</span>{" "}
                {startYear(project)}〜{endYear(project)}
              </p>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">会計区分:</span>{" "}
                {project.accountingCategory.join("、")}
              </p>
              <p style={{ marginBottom: "0.75rem" }}>
                <span className="has-text-weight-bold">実施方法:</span>{" "}
                {project.method.join("、")}
              </p>
              <h4 className="has-text-weight-bold">事業の目的</h4>
              <p
                style={{ marginBottom: "0.75rem" }}
                dangerouslySetInnerHTML={{
                  __html: project.objective.replace("\n", "<br/>"),
                }}
              />
              <h4 className="has-text-weight-bold">事業概要</h4>
              <p
                style={{ marginBottom: "0.75rem" }}
                dangerouslySetInnerHTML={{
                  __html: project.summary.replace("\n", "<br/>"),
                }}
              />
              <h4 className="has-text-weight-bold">予算額・執行額</h4>
              <p>※単位は100万円</p>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table is-bordered is-fullwidth"
                  style={{ marginBottom: "0.75rem" }}
                >
                  <thead>
                    <tr>
                      <th>年度</th>
                      <th style={{ width: "12%" }}>要求額</th>
                      <th style={{ width: "12%" }}>当初予算</th>
                      <th style={{ width: "12%" }}>補正予算</th>
                      <th style={{ width: "12%" }}>前年度から繰越し</th>
                      <th style={{ width: "12%" }}>翌年度へ繰越し</th>
                      <th style={{ width: "12%" }}>予備費等</th>
                      <th style={{ width: "12%" }}>予算計</th>
                      <th style={{ width: "12%" }}>執行額</th>
                    </tr>
                  </thead>
                  <tbody>
                    {budget.map((budget) => {
                      return (
                        <tr key={budget.year}>
                          <th>{budget.year == null ? "-" : budget.year}</th>
                          <td className="has-text-right">
                            {budget.request == null
                              ? "-"
                              : formatCurrency(budget.request)}
                          </td>
                          <td className="has-text-right">
                            {budget.original == null
                              ? "-"
                              : formatCurrency(budget.original)}
                          </td>
                          <td className="has-text-right">
                            {budget.supplementary == null
                              ? "-"
                              : formatCurrency(budget.supplementary)}
                          </td>
                          <td className="has-text-right">
                            {budget.brought == null
                              ? "-"
                              : formatCurrency(budget.brought)}
                          </td>
                          <td className="has-text-right">
                            {budget.carried == null
                              ? "-"
                              : formatCurrency(budget.carried)}
                          </td>
                          <td className="has-text-right">
                            {budget.reserve == null
                              ? "-"
                              : formatCurrency(budget.reserve)}
                          </td>
                          <td className="has-text-right">
                            {budget.total == null
                              ? "-"
                              : formatCurrency(budget.total)}
                          </td>
                          <td className="has-text-right">
                            {budget.executed == null
                              ? "-"
                              : formatCurrency(budget.executed)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <BudgetChart budget={budget} />
              <h4 className="has-text-weight-bold">
                <Tooltip message={words["成果目標"]}>成果目標</Tooltip>
                及び
                <Tooltip message={words["成果実績"]}>成果実績</Tooltip>
                （アウトカム）
              </h4>
              {project.outcome.map((oc, i) => {
                return (
                  <div key={i}>
                    <p>{oc.goal}</p>
                    <p>
                      {oc.indicator}{" "}
                      {oc.target_year
                        ? `（目標：${oc.target_year}年度に${oc.target} ${oc.unit}）`
                        : ""}
                    </p>
                    <div style={{ overflowX: "auto" }}>
                      <table
                        className="table is-bordered is-fullwidth"
                        style={{ marginBottom: "0.75rem" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>年度</th>
                            <th style={{ width: "45%" }}>当初見込み</th>
                            <th style={{ width: "45%" }}>成果実績</th>
                          </tr>
                        </thead>
                        <tbody>
                          {oc.items
                            .filter(
                              (item) =>
                                (!project.startYear ||
                                  project.startYear <= item.year) &&
                                (!project.endYear ||
                                  item.year <= project.endYear)
                            )
                            .map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.year}</td>
                                  <td className="has-text-right">
                                    {item.expected || "-"} {oc.unit}
                                  </td>
                                  <td className="has-text-right">
                                    {item.achievement || "-"} {oc.unit}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
              <h4 className="has-text-weight-bold">
                <Tooltip message={words["活動指標"]}>活動指標</Tooltip>及び
                <Tooltip message={words["活動実績"]}>活動実績</Tooltip>
                （アウトプット）
              </h4>
              {project.output.map((op, i) => {
                return (
                  <div key={i}>
                    <p>{op.indicator}</p>
                    <div style={{ overflowX: "auto" }}>
                      <table
                        className="table is-bordered is-fullwidth"
                        style={{ marginBottom: "0.75rem" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>年度</th>
                            <th style={{ width: "45%" }}>当初見込み</th>
                            <th style={{ width: "45%" }}>活動実績</th>
                          </tr>
                        </thead>
                        <tbody>
                          {op.items
                            .filter(
                              (item) =>
                                (!project.startYear ||
                                  project.startYear <= item.year) &&
                                (!project.endYear ||
                                  item.year <= project.endYear)
                            )
                            .map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.year}</td>
                                  <td className="has-text-right">
                                    {item.expected || "-"} {op.unit}
                                  </td>
                                  <td className="has-text-right">
                                    {item.achievement || "-"} {op.unit}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
              <h4 className="has-text-weight-bold">主要な支出先</h4>
              <div>
                <PayeeTable payees={payees} />
              </div>
              <h4
                className="has-text-weight-bold"
                style={{ marginBottom: "0.75rem" }}
              >
                レビューシートを開く
              </h4>
              <div className="field is-grouped">
                {projects.map((project) => {
                  return (
                    <div key={project.reportYear} className="control">
                      <a
                        className="button is-info"
                        target="_blank"
                        href={project.url}
                      >
                        {project.reportYear}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
