import React from "react";
import { Helmet } from "react-helmet";
import ogpPath from "../images/ogp.png";

const Head = (props) => {
  const title = props.title
    ? `${props.title} - JUDGIT!(ジャジット)`
    : "政府の事業が検索できるサイトJUDGIT!(ジャジット)";
  const description =
    props.description ||
    "JUDGIT!(ジャジット)では、国の各省庁が行う約5,000事業のほぼ全てについて「目的」「内容」「成果」「予算の支払い先」などを誰でも調べることができます。";
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content="https://judgit.net/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="JUDGIT!" />
      <meta
        property="og:image"
        content={`${window.location.origin}/${ogpPath}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default Head;
