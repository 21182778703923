import "@babel/polyfill";
import "url-search-params-polyfill";
import "whatwg-fetch";
import "typeface-noto-sans";

import React from "react";
import { render } from "react-dom";
import { Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { TwitterShareButton, TwitterIcon } from "react-share";

import { setLoading } from "./services";
import ScrollToTop from "./components/ScrollToTop";
import { AboutPage } from "./pages/about";
import { CategoryDetailPage } from "./pages/category-detail";
import { CategoryListPage } from "./pages/category-list";
import { ContactPage } from "./pages/contact";
import { HelpPage } from "./pages/help";
import { MinistryDetailPage } from "./pages/ministry-detail";
import { MinistryListPage } from "./pages/ministry-list";
import { PayeeDetailPage } from "./pages/payee-detail";
import { PayeeListPage } from "./pages/payee-list";
import { PolicyPage } from "./pages/policy";
import { ProjectDetailPage } from "./pages/project-detail";
import { ProjectListPage } from "./pages/project-list";
import { RootPage } from "./pages/root";
import { TermsPage } from "./pages/terms";

import logoKosonippon from "./images/logo_kosonippon.gif";
import logoVdslab from "./images/logo_vdslab.gif";
import logoVisualizingjp from "./images/logo_visualizingjp.gif";
import logoTansa from "./images/logo_tansa.png";

ReactGA.initialize("UA-12701994-7");
const history = createBrowserHistory();
history.listen((location) => {
  const page = location.pathname + location.search + location.hash;
  ReactGA.set({ page });
  ReactGA.pageview(page);
});

const Collaborator = ({ image, name, url }) => {
  return (
    <div className="column has-text-centered">
      <a href={url} target="_blank" rel="noopener">
        <img src={image} alt={name} />
      </a>
    </div>
  );
};

const App = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <div>
          <div
            style={{
              background: "rgb(84, 187, 255)",
              borderBottom: "solid 1px #dbdbdb",
              fontWeight: "bold",
            }}
          >
            <div className="container">
              <div className="nav tabs is-small">
                <ul style={{ border: "none" }}>
                  <li style={{ width: "100%" }}>
                    <Link to="/" style={{ color: "white" }}>
                      Top
                    </Link>
                  </li>
                  <li style={{ width: "100%" }}>
                    <Link to="/about" style={{ color: "white" }}>
                      JUDGIT!の趣旨
                    </Link>
                  </li>
                  <li style={{ width: "100%" }}>
                    <Link to="/help" style={{ color: "white" }}>
                      JUDGIT!の使い方
                    </Link>
                  </li>
                  <li style={{ width: "100%" }}>
                    <Link to="/contact" style={{ color: "white" }}>
                      プロジェクト概要
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", top: "48px", width: "100%" }}>
            <div className="container">
              <div className="is-pulled-right">
                {/*
                <div
                  style={{
                    display: 'inline-block',
                    margin: '0 0.5rem',
                    cursor: 'pointer'
                  }}
                >
                  <FacebookShareButton
                    url={window.location.href}
                    quote='政府の事業が検索できるサイト JUDGIT!'
                    hashtag='judgit'
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
                */}
                <div
                  style={{
                    display: "inline-block",
                    margin: "0 0.5rem",
                    cursor: "pointer",
                  }}
                >
                  <TwitterShareButton
                    url={window.location.href}
                    title="政府の事業が検索できるサイト JUDGIT!"
                    hashtags={["judgit"]}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Switch>
              <Route path="/" component={RootPage} exact />
              <Route path="/about" component={AboutPage} />
              <Route path="/categories" component={CategoryListPage} exact />
              <Route
                path="/categories/:category"
                component={CategoryDetailPage}
              />
              <Route path="/contact" component={ContactPage} />
              <Route path="/help" component={HelpPage} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/ministries" component={MinistryListPage} exact />
              <Route
                path="/ministries/:ministry"
                component={MinistryDetailPage}
              />
              <Route path="/payees" component={PayeeListPage} exact />
              <Route
                path="/payees/:corporateNumber"
                component={PayeeDetailPage}
              />
              <Route path="/policies" component={PolicyPage} />
              <Route path="/projects" component={ProjectListPage} exact />
              <Route
                path="/projects/:projectId"
                component={ProjectDetailPage}
              />
            </Switch>
          </div>
          <section className="section">
            <div className="container">
              <div className="columns is-vcentered">
                <Collaborator
                  name="構想日本"
                  url="http://www.kosonippon.org/"
                  image={logoKosonippon}
                />
                <Collaborator
                  name="日本大学尾上研究室"
                  url="https://vdslab.jp/"
                  image={logoVdslab}
                />
                <Collaborator
                  name="Visualizing.JP"
                  url="https://visualizing.jp/"
                  image={logoVisualizingjp}
                />
                <Collaborator
                  name="特定非営利活動法人Tansa"
                  url="https://tansajp.org/"
                  image={logoTansa}
                />
              </div>
            </div>
          </section>
          <footer className="footer">
            <div className="container">
              <div className="content has-text-centered">
                <p>
                  &copy;2019
                  構想日本・日本大学尾上研究室・Visualizing.JP・特定非営利活動法人Tansa
                </p>
              </div>
            </div>
          </footer>
        </div>
      </ScrollToTop>
    </Router>
  );
};

render(<App />, document.getElementById("content"), () => {
  setTimeout(() => {
    setLoading(false);
  }, 500);
});
