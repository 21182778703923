import React from "react";
import { fetchBudget } from "../../services/api";
import { Chart } from "../../components/chart";

export const CategoryBudgetChart = ({ category }) => {
  const [options, setOptions] = React.useState({
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: "百万円",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [],
  });

  React.useEffect(() => {
    let unmounted = false;
    fetchBudget({ category }).then((data) => {
      if (unmounted) {
        return;
      }
      const minYear = Math.min(...data.map(({ year }) => year));
      const maxYear = Math.max(...data.map(({ year }) => year));
      const years = [];
      for (let y = minYear; y < maxYear; ++y) {
        years.push(y);
      }
      const yearBudget = new Map(data.map((item) => [item.year, item]));
      setOptions(
        Object.assign({}, options, {
          xAxis: {
            categories: years,
          },
          series: [
            {
              name: "当初予算",
              data: years.map((y) => yearBudget.get(y).original),
              stack: "income",
            },
            {
              name: "補正予算",
              data: years.map((y) => yearBudget.get(y).supplementary),
              stack: "income",
            },
            {
              name: "前年度から繰越し",
              data: years.map((y) => yearBudget.get(y).brought),
              stack: "income",
            },
            {
              name: "予備費等",
              data: years.map((y) => yearBudget.get(y).reserve),
              stack: "income",
            },
            {
              name: "執行額",
              data: years.map((y) => yearBudget.get(y).executed),
              stack: "outgo",
            },
            {
              name: "翌年度へ繰越し",
              data: years.map((y) => -yearBudget.get(y).carried),
              stack: "outgo",
            },
          ],
        })
      );
    });
    return () => {
      unmounted = true;
    };
  }, []);

  return <Chart options={options} />;
};
