import heroImage from "../../images/hero-background.jpg";
import logoImage from "../../images/judgit-logo.png";

import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { startYear, endYear, formatCurrency } from "../../services";
import { fetchMinistryCategories, fetchSummary } from "../../services/api";
import { fetchKeywords, fetchPickups } from "../../services/graphcms";
import { BudgetHeatmap } from "./budget-heatmap";
import { Words } from "./words";

const MainButton = ({ label, to }) => {
  return (
    <div className="column is-half">
      <Link to={to} className="button is-large is-info is-fullwidth">
        {label}
      </Link>
    </div>
  );
};

const PayeeTable = ({ payees, keyword }) => {
  if (payees == null || payees.length === 0) {
    return null;
  }
  return (
    <div style={{ overflowX: "auto", marginBottom: "3rem" }}>
      <h3 className="title">該当支出先(支出額上位10件)</h3>
      <p>※支出先には再委託先も含みます。</p>
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-centered" style={{ width: "40%" }}>
              支出先
            </th>
            <th className="has-text-centered" style={{ width: "30%" }}>
              件数
            </th>
            <th className="has-text-centered" style={{ width: "30%" }}>
              支出額（百万円）
            </th>
          </tr>
        </thead>
        <tbody>
          {payees.map((payee, i) => {
            return (
              <tr key={i}>
                <td>
                  <Link to={`/payees/${payee.corporateNumber}`}>
                    {payee.name}
                  </Link>
                </td>
                <td className="has-text-right">{payee.totalCount}</td>
                <td className="has-text-right">
                  {formatCurrency(payee.totalAmount)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="is-pulled-right">
        <Link className="button is-info" to={`/payees?keyword=${keyword}`}>
          🔍続きを見る
        </Link>
      </div>
    </div>
  );
};

const ProjectTable = ({ projects, keyword }) => {
  if (projects == null || projects.length === 0) {
    return null;
  }
  return (
    <div style={{ overflowX: "auto", marginBottom: "1rem" }}>
      <h3 className="title">該当事業(予算額上位10件)</h3>
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-centered" style={{ width: "20%" }}>
              府省庁
            </th>
            <th className="has-text-centered" style={{ width: "40%" }}>
              事業名
            </th>
            <th className="has-text-centered" style={{ width: "20%" }}>
              事業期間
            </th>
            <th className="has-text-centered" style={{ width: "20%" }}>
              予算規模（百万円）
            </th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, i) => {
            return (
              <tr key={i}>
                <td>
                  <Link to={`/ministries/${project.ministry}`}>
                    {project.ministry}
                  </Link>
                </td>
                <td>
                  <Link to={`/projects/${project.projectId}`}>
                    {project.projectName}
                  </Link>
                </td>
                <td className="has-text-right">
                  開始：{startYear(project)}
                  <br />
                  終了：{endYear(project, "予定なし")}
                </td>
                <td className="has-text-right">
                  最小：{formatCurrency(project.minBudget)}
                  <br />
                  最大：{formatCurrency(project.maxBudget)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="is-pulled-right">
        <Link className="button is-info" to={`/projects?keyword=${keyword}`}>
          🔍続きを見る
        </Link>
      </div>
    </div>
  );
};

export class RootPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CrossFilter: null,
      searchKeyword: "",
      loading: false,
      projects: null,
      payees: null,
      groups: [],
      keywords: [],
      pickups: [],
    };
  }

  componentDidMount() {
    fetchMinistryCategories().then((data) => {
      if (this.unmounted) {
        return;
      }
      this.setState({
        groups: data,
      });
    });
    fetchKeywords().then(({ data }) => {
      if (this.unmounted) {
        return;
      }
      this.setState({
        keywords: data.keywords.map(({ keyword }) => keyword),
      });
    });
    fetchPickups().then(({ data }) => {
      if (this.unmounted) {
        return;
      }
      this.setState({
        pickups: data.pickups,
      });
    });

    import("./crossfilter").then(({ CrossFilter }) => {
      this.setState({ CrossFilter });
    });

    this.search();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.search();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const {
      CrossFilter,
      searchKeyword,
      loading,
      projects,
      payees,
      groups,
      keywords,
      pickups,
    } = this.state;

    return (
      <div>
        <Head />
        <section
          className="hero is-black is-medium"
          style={{
            backgroundImage: `linear-gradient(rgba(20, 150, 237, 0.9) 0px, rgba(255, 255, 255, 1) 100%), url('${heroImage}')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">
                <img src={logoImage} alt="JUDGIT!" />
              </h1>
              <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      const keyword = this.refs.keyword.value;
                      if (keyword) {
                        this.props.history.push(`/?keyword=${keyword}`);
                      }
                    }}
                  >
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <input
                          ref="keyword"
                          className="input is-rounded"
                          placeholder="検索キーワード"
                        />
                      </div>
                      <div className="control">
                        <button
                          className={`button is-info is-rounded${
                            loading ? " is-loading" : ""
                          }`}
                        >
                          🔍 検索
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <Words
              words={keywords}
              onClick={(keyword) => {
                this.refs.keyword.value = keyword;
                this.props.history.push(`/?keyword=${keyword}`);
              }}
            />
            <div ref="searchResults">
              {projects == null ? null : projects.length === 0 ? (
                <p>該当なし</p>
              ) : null}
              <ProjectTable projects={projects} keyword={searchKeyword} />
              <PayeeTable payees={payees} keyword={searchKeyword} />
            </div>
            <div className="columns is-multiline">
              <MainButton label="📋 行政事業検索" to="/projects" />
              <MainButton label="🏢 主要支出先検索" to="/payees" />
              <MainButton label="🏛 府省庁別検索" to="/ministries" />
              <MainButton label="🗓 主要施策別検索" to="/categories" />
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h2 className="title has-text-centered">トピックス</h2>
            <div className="columns is-multiline">
              {pickups.map(({ text, keyword }, i) => {
                return (
                  <div key={i} className="column is-half">
                    <article className="media">
                      <figure className="media-left">
                        <p className="image is-64x64">
                          <svg width="64" height="64">
                            <text
                              x="32"
                              y="32"
                              textAnchor="middle"
                              dominantBaseline="central"
                              fontSize="20"
                              fill="black"
                            >
                              {(i + 1).toString().padStart(2, "0")}
                            </text>
                          </svg>
                        </p>
                      </figure>
                      <div className="media-content">
                        <div className="content">
                          <p>{text}</p>
                        </div>
                        <Link
                          className="button is-info is-small is-fullwidth"
                          to={`/projects?keyword=${keyword}`}
                        >
                          🔍 検索
                        </Link>
                      </div>
                    </article>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-two-third">
                <BudgetHeatmap
                  data={groups}
                  onClickElement={(result) => {
                    if (result.ministry && result.category) {
                      const params = new URLSearchParams();
                      for (const k of ["ministry", "category"]) {
                        const v = result[k];
                        if (v) {
                          params.set(k, v);
                        }
                      }
                      this.props.history.push(`/policies?${params.toString()}`);
                    } else if (result.ministry) {
                      this.props.history.push(`/ministries/${result.ministry}`);
                    } else if (result.category) {
                      this.props.history.push(`/categories/${result.category}`);
                    }
                  }}
                />
              </div>
              <div className="column is-one-third">
                {CrossFilter == null ? (
                  "loading..."
                ) : (
                  <CrossFilter history={this.props.history} />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  search() {
    const params = new URLSearchParams(this.props.location.search);
    const keyword = params.get("keyword");
    if (!keyword) {
      return;
    }
    this.refs.keyword.value = keyword;
    this.setState({
      searchKeyword: keyword,
      loading: true,
    });
    fetchSummary({ keyword }).then(({ projects, payees }) => {
      this.setState({ loading: false, projects, payees });
      const { top } = this.refs.searchResults.getBoundingClientRect();
      window.scrollTo({
        top: window.pageYOffset + top,
        behavior: "smooth",
      });
    });
  }
}
