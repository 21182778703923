import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { fetchBudget } from "../../services/api";
import { CategoryBudgetChart } from "./category-budget-chart";

const toQueryString = (args) => {
  const params = new URLSearchParams();
  for (const k in args) {
    if (args[k]) {
      params.set(k, args[k]);
    }
  }
  return params.toString();
};

export class CategoryDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      budgets: [],
    };
  }

  componentDidMount() {
    const { category } = this.props.match.params;
    fetchBudget({ category }).then((budgets) => {
      this.setState({
        budgets,
      });
    });
  }

  render() {
    const { category } = this.props.match.params;
    return (
      <div>
        <Head title={category} />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb" style={{ overflowX: "auto" }}>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li>
                  <Link to="/categories">主要施策</Link>
                </li>
                <li className="is-active">
                  <a>{category}</a>
                </li>
              </ul>
            </nav>
            <h1 className="title">{category}</h1>
            <div className="field">
              <CategoryBudgetChart category={category} />
            </div>
            <div className="field">
              <div className="control">
                <Link
                  className="button is-info"
                  to={{
                    pathname: "/projects",
                    search: toQueryString({ category }),
                  }}
                >
                  事業一覧を検索
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
