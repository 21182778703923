import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";

export class TermsPage extends React.Component {
  render() {
    return (
      <div>
        <Head title="terms" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <Link to="/terms">サイト利用にあたって</Link>
                </li>
              </ul>
            </nav>
            <div className="content">
              <h2>利用規約</h2>
              <div>
                <h3>本サイトをご利用するにあたり</h3>
                <p>
                  一般社団法人構想日本、、日本大学文理学部情報科学科尾上洋介研究室、Visualizing.JP、特定非営利活動法人ワセダクロニクルの共同プロジェクト（以下当プロジェクト）で運営する公式サイト「JUDGIT！」（以下当サイト）を快適にご利用いただくために、本規約内容をご確認いただき、ご同意された場合のみご利用ください。
                  なお、ご利用いただいた場合は、本規約の内容に同意されたものとみなします。本サイトの利用は、利用者自らの判断と責任において行うものとし、当プロジェクトは一切の責を負わないものとします。
                  当サイトの利用規約は、予告なく変更することがございますので、最新の内容をご確認くださいますようお願いいたします。
                </p>
                <h3>1. 著作権について</h3>
                <p>
                  当サイトの著作権は当プロジェクト又は著作権を有する第三者にあります。転載、複製、販売、出版、蓄積又は転送等私的利用の範囲を超えて使用することができません。
                </p>
                <h3>2. 免責事項について</h3>
                <p>
                  当サイトで提供する情報は、その正確性と再申請の確保に努めていますが、完全さを保証するものではありません。当プロジェクトは、当サイトの内容に関するいかなる間違い、不掲載についても一切の責任を負うものではありません。
                </p>

                <ul>
                  <li>
                    当サイトの内容を予告なしに変更・削除・中断・終了することがあります。これによりトラブルや損害が発生しても一切の責任は負えないことをご了承ください。
                  </li>
                  <li>
                    当サイトの利用、閲覧は、自己責任においてご利用ください。利用したことによるいかなるトラブル、あるいは損失、損害についても一切の責任を負いません。
                  </li>
                  <li>
                    当サイトからリンクしているサイトや、リンク先のサイトに関して生じたいかなる損害についても一切の賠償する責任は負いません。
                  </li>
                </ul>
                <h3>3. リンクについて</h3>
                <p>
                  当サイトへのリンクを希望される場合は以下の事項をご確認のうえ、当プロジェクトまでご連絡いただけますようお願いいたします。
                </p>
                <ul>
                  <li>
                    リンクをする場合のＵＲＬはトップページ（
                    <a href="https://judgit.net/">https://judgit.net/</a>
                    ）としてください。
                  </li>
                  <li>
                    次のようなサイトからのリンクはお断りいたします。
                    <ul>
                      <li>
                        （法律、条令、法令や）公序良俗に反する内容、バナーを含んだサイト。
                      </li>
                      <li>
                        特定非営利活動法人ワセダクロニクル、一般社団法人構想日本、日本大学尾上洋介研究室、または第三者の財産もしくはプライバシーなどを侵害する恐れのあるサイト。
                      </li>
                      <li> 事実とは異なる内容の紹介によるリンク</li>
                      <li>
                        当サイトが提供するサービスを誹謗中傷したり、信用を毀損する内容を含んだサイト。
                      </li>
                    </ul>
                  </li>
                  <li>相互リンクなどへのご要望には、原則お応えできません。</li>
                </ul>
                <h3>4. 推奨環境について</h3>
                <p>
                  本サイトを快適にご利用いただくために、以下をブラウザ推奨環境としています。
                </p>
                <h4>PC</h4>
                <ul>
                  <li>Google Chrome 最新版</li>
                  <li>Mozilla Firefox　最新版</li>
                  <li>Safari　最新版</li>
                  <li>Microsoft Edge　最新版</li>
                </ul>
                <h4>スマートフォン</h4>
                <ul>
                  <li>Google Chrome for Android 最新版</li>
                  <li>iOS Safari　最新版</li>
                </ul>

                <p>
                  ※上記ご利用環境を満たしている場合でも、お客様の端末設定、ネットワーク環境などによりご利用いただけない場合もございますので予めご了承ください。
                </p>
                <p>
                  ※ご利用環境につきましては、予告なく変更させていただく場合がございます。
                </p>
              </div>
              <h2>プライバシーポリシー</h2>
              <div>
                <p>
                  「JUDGIT!」は、一般社団法人構想日本、日本大学文理学部情報科学科尾上洋介研究室、Visualizing.JP、特定非営利活動法人ワセダクロニクルの共同プロジェクト（以下当プロジェクト）によって提供されています。
                </p>
                <p>
                  当プロジェクトは、皆様よりご提供いただく個人情報（以下、本個人情報）を以下の方針に従って取り扱います。
                </p>
                <h3>法令の遵守</h3>
                <p>
                  当プロジェクトは、本個人情報の取得、利用その他の取り扱いについて、個人情報の保護に関する法律及びこのプライバシーポリシーを遵守します。なお、本個人情報を提供いただく際に、ウェブサイト内の個別ページにおいて、本プライバシーポリシーと異なる定めがある場合はそれを優先的に適用します。
                </p>
                <h3>本個人情報の利用目的</h3>
                <p>
                  本個人情報を提供いただく際にウェブサイト内の個別ページで掲げている目的の他、当プロジェクトからの各種ご案内のために、本個人情報を利用します。その他目的に利用しようとする場合、個別に公表又は通知します。
                </p>
                <h3>従業員及び委託先の監督</h3>
                <ol>
                  <li>
                    当プロジェクトは、事務局に本個人情報を取り扱う管理責任者を置き、本個人情報を適切に管理します。
                  </li>
                  <li>
                    本個人情報にアクセスする権限を持つ役員及び従業員のみが本個人情報を取り扱います。
                  </li>
                  <li>
                    当プロジェクトは、本個人情報の利用目的に必要な範囲で、本個人情報を取り扱う業務を第三者に委託することがあります。その場合、委託先に対して、本個人情報の適切な取り扱いについて、必要かつ適切な監督を行います。
                  </li>
                </ol>
                <h3>第三者への開示</h3>
                <p>
                  当プロジェクトは、以下のいずれかに該当する場合を除いて、本個人情報を第三者に開示しません。
                </p>
                <ol>
                  <li>本個人情報を提供するご本人の同意がある場合</li>
                  <li>企業等が従うべき法的義務遂行のために必要な場合</li>
                  <li>
                    行政機関、裁判所等の要請を受け、当プロジェクトが開示の必要性を判断した場合
                  </li>
                  <li>
                    当プロジェクト、本個人情報をご提供いただいたご本人又はご本人以外の第三者の生命財産又は名誉信用の保護のために必要と認められる等、正当な事由がある場合
                  </li>
                  <li>個人が特定されない方法により利用する場合</li>
                  <li>
                    その他、法令により第三者への開示又は提供が許容されている場合
                  </li>
                </ol>
                <h3>クッキー（Cookie）情報等の取り扱い</h3>
                <p>
                  当プロジェクトはウェブサイトにおいて、クッキー（Cookie）、Webビーコン（Web
                  Beacon）その他の類似技術を用いることがあります。いずれの場合も、収集される情報に新たな個人情報は含まれませんが、既にご提供いただいている本個人情報と関連付けて利用する場合があります。
                </p>
                <ol>
                  <li>
                    クッキーとは、個人のコンピュータにウェブサイトの利用履歴などの情報を保存する技術です。当プロジェクトのウェブサイトを訪問した際に保存されたクッキーは、主に、当プロジェクトのウェブサイトやサービスをより便利にご利用いただくための情報収集及び第三者配信事業者を通じての効果的な広告配信のために利用されています。
                  </li>
                  <li>
                    Webビーコンは、ウェブサイト又はHTMLメールに埋め込まれたJavaScriptや小さな画像ファイル等を利用し、ウェブサイト及びHTMLメールの閲覧状況を収集し、分析することです。Webビーコンを利用する主な目的は、特定のウェブページ等の利用状況を統計的に把握し、サービス向上に役立てることにあります。
                  </li>
                </ol>
                <h3>第三者配信事業者による広告表示について</h3>
                <p>
                  当プロジェクトは、第三者配信事業者に、インターネット上の様々なサイトでの広告配信を委託する場合があります。こうした第三者配信事業者は、お客様が当プロジェクトのウェブサイトを訪問した際のクッキー情報を認識し、当プロジェクトの広告を配信する場合があります。当プロジェクトの委託先である第三者配信事業者によるクッキーの利用によって、お客様の個人情報が第三者配信事業者に取得されることはありません。なお、第三者配信事業者によっては、自社によるクッキーを利用した広告配信を無効化する手段を独自に公開している場合もあります。
                </p>
                <h3>本個人情報に関するお問い合わせ</h3>
                <ol>
                  <li>
                    本個人情報は、原則としてご本人だけが、開示・訂正・追加・削除・利用停止及び消去（以下「開示等」）を求めることができます。
                  </li>
                  <li>
                    本個人情報の開示等を請求する場合、原則として以下の書類を同封のうえ、当プロジェクトに郵送してください。
                    <ol className="is-upper-roman">
                      <li>
                        ご本人が請求する場合
                        <ul>
                          <li>
                            ご本人の氏名と住所が記載された公的証明書（①運転免許証のコピー、又は②健康保険の被保険者証、住民基本台帳カード、パスポート、外国人登録証明書、年金手帳、公共料金の領収書等の中から２種類のコピー）
                          </li>
                        </ul>
                      </li>
                      <li>
                        ご本人の代理人の方が請求される場合
                        <ol className="is-lower-roman">
                          <li>
                            ご本人の氏名と住所が記載された公的証明書（①運転免許証のコピー、又は②健康保険の被保険者証、住民基本台帳カード、パスポート、外国人登録証明書、年金手帳、公共料金の領収書等の中から２種類のコピー）
                          </li>
                          <li>
                            代理人の氏名と住所が記載された公的証明書（①運転免許証のコピー、又は②健康保険の被保険者証、住民基本台帳カード、パスポート、外国人登録証明書、年金手帳、公共料金の領収書等の中から２種類のコピー）
                          </li>
                          <li>
                            本人からの委任状（未成年者又は成年被後見人の法定代理人の方が請求される場合、委任状に代えて、ご本人との関係がわかる戸籍謄本若しくは抄本、又は住民票をご提出いただくことも可能です。）
                          </li>
                        </ol>
                      </li>
                      <li>
                        郵送先「〒102-0093　東京都千代田区平河町2-9-2　エスパリエ平河町3F　一般社団法人構想日本」
                      </li>
                      <li>
                        開示等のご請求に伴い取得した本個人情報は、開示等のご請求に対応させていただくために必要な範囲でのみ取り扱います。なお、提出いただいた書類は返却いたしません。開示等のご請求に対応させていただいた後、適切に管理、廃棄いたします。
                      </li>
                    </ol>
                  </li>
                  <li>
                    このプライバシーポリシー及び本個人情報に関するお問い合わせ、ご相談（本個人情報の開示等請求の方法等）については、judgit@kosonippon.org宛にメールをお送りください。
                  </li>
                </ol>
                <h3>継続的取り組み</h3>
                <p>
                  当プロジェクトは、本個人情報の漏洩、滅失及び毀損を防ぐため、必要かつ適切な安全管理措置を講じるとともに、その改善に努めます。
                </p>
                <h3>個人情報取扱責任者</h3>
                <p>一般社団法人構想日本 理事　伊藤伸</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
