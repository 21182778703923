const request = (query) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ query }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const url =
    "https://api-ap-northeast-1.graphcms.com/v2/cjwxpjuve2qz801it3od1ziow/master";
  return window.fetch(url, options).then((response) => response.json());
};

export const fetchKeywords = () => {
  const query = `{
    keywords: keywords (orderBy: keyword_ASC) {
      keyword
    }
  }`;
  return request(query);
};

export const fetchPickups = () => {
  const query = `{
    pickups: pickups (orderBy: order_ASC) {
      text,
      keyword
    }
  }`;
  return request(query);
};
