import React from "react";
import { formatCurrency } from "../../services";
import { fetchProjects } from "../../services/api";
import { ProjectTable } from "./project-table";

const TotalBudget = ({ budgetMap }) => {
  const budgets = Array.from(budgetMap.entries()).map(([year, amount]) => ({
    year,
    amount,
  }));
  budgets.sort((b1, b2) => b2.year - b1.year);
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-centered">年度</th>
            <th className="has-text-centered">合計予算額（百万円）</th>
          </tr>
        </thead>
        <tbody>
          {budgets.map(({ year, amount }, i) => {
            return (
              <tr key={i}>
                <td>{year}</td>
                <td className="has-text-right">{formatCurrency(amount)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const uniqueItems = (items, key) => {
  const map = new Map();
  for (const item of items) {
    const keys = new Set();
    for (const o of key(item)) {
      keys.add(o);
    }
    for (const o of keys) {
      if (!map.has(o)) {
        map.set(o, 0);
      }
      map.set(o, map.get(o) + 1);
    }
  }
  return Array.from(map.entries());
};

export class KeywordSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      projects: [],
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (
      params.get("keyword") ||
      params.get("ministry") ||
      params.get("category") ||
      (params.get("hasSupplementary") && params.get("reportYear"))
    ) {
      this.loadProjects();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.loadProjects();
    }
  }

  render() {
    const { loading, projects } = this.state;

    const params = new URLSearchParams(this.props.location.search);

    const selectedMinistry = params.get("ministry");
    const availableAccountingCategories = uniqueItems(
      projects,
      (project) => project.accountingCategory
    );
    const availableMethods = uniqueItems(projects, (project) => project.method);
    const availableMinistries = uniqueItems(projects, (project) => [
      project.ministry,
    ]);
    const availableBureaus = uniqueItems(
      projects.filter(({ ministry }) => ministry === selectedMinistry),
      (project) => [project.bureau]
    );
    const availableCategories = uniqueItems(
      projects,
      (project) => project.category
    );
    const availableYears = [];
    for (let year = new Date().getFullYear(); year >= 1929; --year) {
      availableYears.push(year);
    }
    const availableReportYears = [];
    for (let year = new Date().getFullYear(); year >= 2015; --year) {
      availableReportYears.push(year);
    }

    const totalBudget = new Map();
    for (const project of projects) {
      for (const { year, amount } of project.budget) {
        if (!totalBudget.has(year)) {
          totalBudget.set(year, 0);
        }
        totalBudget.set(year, totalBudget.get(year) + amount);
      }
    }

    return (
      <div>
        <div style={{ marginBottom: "2rem" }}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.handleChangeFormValue();
            }}
          >
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  ref="keyword"
                  className="input is-medium is-rounded"
                  defaultValue={params.get("keyword") || ""}
                  placeholder="検索キーワード"
                  required
                />
              </div>
              <div className="control">
                <span className="select is-medium is-hidden-touch">
                  <select
                    ref="condition"
                    defaultValue={params.get("condition") || "and"}
                  >
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </select>
                </span>
              </div>
              <div className="control">
                <button
                  className={`button is-info is-medium is-rounded${
                    loading ? " is-loading" : ""
                  }`}
                >
                  検索
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <nav className="panel" style={{ position: "sticky", top: "0" }}>
              <p className="panel-heading">絞込み (該当{projects.length}件)</p>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">会計区分</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="accountingCategory"
                          value={params.get("accountingCategory") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableAccountingCategories.map(
                            ([accountingCategory, count]) => {
                              return (
                                <option
                                  key={accountingCategory}
                                  value={accountingCategory}
                                >
                                  {accountingCategory}({count})
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">実施方法</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="method"
                          value={params.get("method") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableMethods.map(([method, count]) => {
                            return (
                              <option key={method} value={method}>
                                {method}({count})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">府省庁</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="ministry"
                          value={params.get("ministry") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableMinistries.map(([ministry, count]) => {
                            return (
                              <option key={ministry} value={ministry}>
                                {ministry}({count})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">担当部局</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="bureau"
                          value={params.get("bureau") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableBureaus.map(([bureau, count]) => {
                            return (
                              <option key={bureau} value={bureau}>
                                {bureau}({count})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">主要政策</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="category"
                          value={params.get("category") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableCategories.map(([category, count]) => {
                            return (
                              <option key={category} value={category}>
                                {category}({count})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="checkbox">
                      <input
                        ref="checkOnlyOneCategory"
                        type="checkbox"
                        checked={!!params.get("checkOnlyOneCategory")}
                        onChange={() => {
                          this.handleChangeFormValue();
                        }}
                      />
                      単一主要政策のみ
                    </label>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">実施年度</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="projectYear"
                          value={params.get("projectYear") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableYears.map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">レビューシート公開年度</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          ref="reportYear"
                          value={params.get("reportYear") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        >
                          <option />
                          {availableReportYears.map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">最小予算額（百万円）</label>
                    <div className="control">
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.handleChangeFormValue();
                        }}
                      >
                        <div className="field has-addons">
                          <div className="control is-expanded">
                            <input
                              ref="minBudget"
                              className="input"
                              type="number"
                              min="0"
                              defaultValue={params.get("minBudget") || ""}
                            />
                          </div>
                          <div className="control">
                            <button
                              className={`button${
                                loading ? " is-loading" : ""
                              }`}
                            >
                              検索
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label">最大予算額（百万円）</label>
                    <div className="control">
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.handleChangeFormValue();
                        }}
                      >
                        <div className="field has-addons">
                          <div className="control is-expanded">
                            <input
                              ref="maxBudget"
                              className="input"
                              type="number"
                              min="0"
                              defaultValue={params.get("maxBudget") || ""}
                            />
                          </div>
                          <div className="control">
                            <button
                              className={`button${
                                loading ? " is-loading" : ""
                              }`}
                            >
                              検索
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <div className="field">
                    <label className="label"></label>
                    <div className="control">
                      <label className="checkbox">
                        <input
                          ref="hasSupplementary"
                          type="checkbox"
                          defaultChecked={params.get("hasSupplementary") || ""}
                          onChange={() => {
                            this.handleChangeFormValue();
                          }}
                        />
                        補正予算付き事業のみ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <div className="control">
                  <button
                    className="button is-fullwidth"
                    onClick={() => {
                      this.refs.minBudget.value = null;
                      this.refs.maxBudget.value = null;
                      this.props.history.push(
                        `/projects?keyword=${params.get("keyword")}`
                      );
                    }}
                  >
                    リセット
                  </button>
                </div>
              </div>
            </nav>
          </div>
          <div className="column">
            <div ref="searchResults">
              <div>
                <TotalBudget budgetMap={totalBudget} />
              </div>
              <div>
                <ProjectTable projects={projects} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  loadProjects() {
    this.setState({
      loading: true,
    });
    const params = new URLSearchParams(this.props.location.search);
    const options = {};
    for (const [k, v] of params) {
      options[k] = v;
    }
    fetchProjects(options).then((projects) => {
      this.setState({
        loading: false,
        projects,
      });
      const { top } = this.refs.searchResults.getBoundingClientRect();
      window.scrollTo({
        top: window.pageYOffset + top,
        behavior: "smooth",
      });
    });
  }

  buildParams() {
    const params = new URLSearchParams();
    params.set("keyword", this.refs.keyword.value);
    params.set("condition", this.refs.condition.value);
    if (this.refs.accountingCategory.value) {
      params.set("accountingCategory", this.refs.accountingCategory.value);
    }
    if (this.refs.method.value) {
      params.set("method", this.refs.method.value);
    }
    if (this.refs.ministry.value) {
      params.set("ministry", this.refs.ministry.value);
    }
    if (this.refs.bureau.value) {
      params.set("bureau", this.refs.bureau.value);
    }
    if (this.refs.category.value) {
      params.set("category", this.refs.category.value);
    }
    if (this.refs.checkOnlyOneCategory.checked) {
      params.set("checkOnlyOneCategory", 1);
    }
    if (this.refs.projectYear.value) {
      params.set("projectYear", this.refs.projectYear.value);
    }
    if (this.refs.maxBudget.value) {
      params.set("maxBudget", this.refs.maxBudget.value);
    }
    if (this.refs.minBudget.value) {
      params.set("minBudget", this.refs.minBudget.value);
    }
    if (this.refs.hasSupplementary.checked) {
      params.set("hasSupplementary", 1);
    }
    if (this.refs.reportYear.value) {
      params.set("reportYear", this.refs.reportYear.value);
    }
    return params;
  }

  handleChangeFormValue(event) {
    const params = this.buildParams();
    this.props.history.push(`/projects?${params.toString()}`);
  }
}
