import React from "react";
import * as d3 from "d3";
import { ministries, categories } from "../../constants";

export const BudgetHeatmap = (props) => {
  const defaultProps = {
    data: [],
    onClickElement: () => {},
  };
  const { data, onClickElement } = Object.assign(defaultProps, props);

  const items = new Map(
    ministries.map((ministry) => {
      return [
        ministry,
        new Map(
          categories.map((category) => {
            return [category, {}];
          })
        ),
      ];
    })
  );
  for (const item of data) {
    items.get(item.ministry).set(item.category, item);
  }

  const r = 15;
  const sizeScale = d3
    .scaleSqrt()
    .domain([1, d3.max(data, (item) => item.count)])
    .range([5, r]);
  const color = d3
    .scaleThreshold()
    .domain([100, 10000, 1000000])
    .range([
      d3.rgb(84, 187, 255).brighter(),
      d3.rgb(84, 187, 255),
      d3.rgb(84, 187, 255).darker(),
      d3.rgb(84, 187, 255).darker().darker(),
    ]);

  const lineWidth = 1;
  const leftMargin = 150;
  const rightMargin = 20;
  const topMargin = 180;
  const contentWidth = r * 2 * categories.length;
  const contentHeight = r * 2 * ministries.length;
  const svgWidth = contentWidth + leftMargin + rightMargin;
  const svgHeight = contentHeight + topMargin;

  return (
    <div
      className="svg-box"
      style={{ paddingTop: `${((svgHeight / svgWidth) * 100).toFixed()}%` }}
    >
      <svg viewBox={`0,0,${svgWidth},${svgHeight}`}>
        <g transform={`translate(${leftMargin},${topMargin})`}>
          <g>
            {ministries.map((ministry, i) => {
              return (
                <g
                  key={i}
                  transform={`translate(0,${i * r * 2 + r})`}
                  onClick={() => onClickElement({ ministry })}
                  style={{ cursor: "pointer" }}
                >
                  <rect
                    y={-lineWidth / 2}
                    height={lineWidth}
                    width={contentWidth}
                    fill="#888"
                  />
                  <text textAnchor="end">{ministry}</text>
                </g>
              );
            })}
          </g>
          <g>
            {categories.map((category, j) => {
              return (
                <g
                  key={j}
                  transform={`translate(${j * r * 2 + r},0)`}
                  onClick={() => onClickElement({ category })}
                  style={{ cursor: "pointer" }}
                >
                  <rect
                    x={-lineWidth / 2}
                    width={lineWidth}
                    height={contentHeight}
                    fill="#888"
                  />
                  <text transform={`rotate(-60)`}>{category}</text>
                </g>
              );
            })}
          </g>
          <g>
            {ministries.map((ministry, i) => {
              return (
                <g key={i}>
                  {categories.map((category, j) => {
                    const { executed, count } = items
                      .get(ministry)
                      .get(category);
                    return (
                      <g key={j}>
                        <circle
                          r={count ? sizeScale(count) : 0}
                          cx={j * r * 2 + r}
                          cy={i * r * 2 + r}
                          fill={color(executed)}
                          style={{
                            cursor: "pointer",
                            transitionProperty: "r",
                            transitionDuration: "1s",
                          }}
                          onClick={() => onClickElement({ ministry, category })}
                        />
                      </g>
                    );
                  })}
                </g>
              );
            })}
          </g>
        </g>
      </svg>
    </div>
  );
};
