import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../services";

export class PayeeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      order: {
        column: "amount",
        desc: true,
      },
    };
  }

  render() {
    const { filter } = this.state;
    const payees = this.props.payees.filter((payee) => {
      if (
        filter &&
        !payee.name.includes(filter) &&
        !payee.description.includes(filter)
      ) {
        return false;
      }
      return true;
    });

    if (this.state.order) {
      const { column } = this.state.order;
      payees.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      if (this.state.order.desc) {
        payees.reverse();
      }
    }

    return (
      <div>
        <div className="field">
          <div className="control">
            <input
              className="input"
              placeholder="絞り込みキーワード"
              onChange={(event) => {
                this.setState({ filter: event.target.value });
              }}
            />
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            className="table is-bordered is-fullwidth"
            style={{ marginBottom: "0.75rem" }}
          >
            <thead>
              <tr>
                <th
                  style={{ cursor: "pointer", width: "10%" }}
                  onClick={() => {
                    this.changeOrder("year");
                  }}
                >
                  年度
                </th>
                <th
                  style={{ cursor: "pointer", width: "30%" }}
                  onClick={() => {
                    this.changeOrder("name");
                  }}
                >
                  支出先
                </th>
                <th
                  style={{ cursor: "pointer", width: "40%" }}
                  onClick={() => {
                    this.changeOrder("description");
                  }}
                >
                  業務概要
                </th>
                <th
                  style={{ cursor: "pointer", width: "20%" }}
                  onClick={() => {
                    this.changeOrder("amount");
                  }}
                >
                  支出額（百万円）
                </th>
              </tr>
            </thead>
            <tbody>
              {payees.map((payee, i) => {
                return (
                  <tr key={i}>
                    <td>{payee.year}</td>
                    {payee.corporate_number ? (
                      <td>
                        <Link to={`/payees/${payee.corporate_number}`}>
                          {payee.name}
                        </Link>
                      </td>
                    ) : (
                      <td>{payee.name}</td>
                    )}
                    <td>{payee.description}</td>
                    <td className="has-text-right">
                      {formatCurrency(payee.amount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  changeOrder(column) {
    const order = {
      column,
      desc: false,
    };
    if (this.state.order.column === column) {
      order.desc = !this.state.order.desc;
    }
    this.setState({ order });
  }
}
