import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { words } from "../../constants";

export class HelpPage extends React.Component {
  render() {
    return (
      <div>
        <Head title="help" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <Link to="/help">JUDGIT!の使い方</Link>
                </li>
              </ul>
            </nav>
            <div className="content">
              <h2>利用者へのメッセージ</h2>
              <div>
                <p>
                  もし、あなたの財布にあったお金が、あなたの知らない間に無駄な買い物に使われていたとしたら、どうしますか？
                </p>
                <p>
                  国の財布に入ったお金はあなたのお金です。そのお金が正しく使われているか、あなたは厳しくチェックする権利があります。
                </p>
                <p>
                  あなたの財布のお金の使われ方を簡単にチェックできるよう、膨大な数字の羅列をデーターベースにしてみました。
                </p>
                <p>
                  もし、親の介護しているあなたがふと「介護費用で家計が大変だ」と感じたのから、「
                  <Link to="/projects?keyword=介護">介護</Link>
                  」と入れて検索してみてください。
                </p>
                <p>
                  もし、休憩時間もなく働いているあたながふと「こんなに働いているのに賃金が上がらないのはなんでだろう」と思ったら、「
                  <Link to="/projects?keyword=労働">労働</Link>
                  」と入れて検索してみてください。
                </p>
                <p>
                  もし、あたなが子どもにかかる学費が負担に感じているなら、「
                  <Link to="/projects?keyword=教育">教育</Link>
                  」と入れて検索してみてください。
                </p>
                <p>
                  平和のことを調べてもいいかもしれません。原発や沖縄のことだってOKです。あなたのお金を国が何に使ったのかを調べることができます。
                </p>
                <p>
                  身の回りで起こったことに関心を持ったあなただからこそ、”何か”を発見できるかもしれません。
                </p>
                <p>
                  このデータベースは無料で使えます。あなたの財布にあったお金を国がどう使ったのか、チェックしてみましょう。
                </p>
              </div>
              <h2>JUDGIT!の使い方</h2>
              <p>COMING SOON</p>
              <h2>用語解説</h2>
              <h3>主要施策</h3>
              <p>{words["主要施策"]}</p>
              <h3>会計区分</h3>
              <h4>一般会計</h4>
              <p>{words["一般会計"]}</p>
              <h4>特別会計</h4>
              <p>{words["特別会計"]}</p>
              <h3>実施方法</h3>
              <h4>直接実施</h4>
              <p>{words["直接実施"]}</p>
              <h4>委託・請負</h4>
              <p>{words["委託・請負"]}</p>
              <h4>交付</h4>
              <p>{words["交付"]}</p>
              <h3>予算額・執行額</h3>
              <h4>要求額</h4>
              <p>{words["要求額"]}</p>
              <h4>当初予算</h4>
              <p>{words["当初予算"]}</p>
              <h4>補正予算</h4>
              <p>{words["補正予算"]}</p>
              <h4>前年度から繰越し</h4>
              <p>{words["前年度から繰越し"]}</p>
              <h4>翌年度へ繰越し</h4>
              <p>{words["翌年度へ繰越し"]}</p>
              <h4>予備費等</h4>
              <p>{words["予備費等"]}</p>
              <h3>成果目標及び成果実績（アウトカム）</h3>
              <h4>成果目標</h4>
              <p>{words["成果目標"]}</p>
              <h4>成果指標</h4>
              <p>{words["成果指標"]}</p>
              <h4>成果実績</h4>
              <p>{words["成果実績"]}</p>
              <h3>活動指標及び活動実績（アウトプット）</h3>
              <h4>活動指標</h4>
              <p>{words["活動指標"]}</p>
              <h4>活動実績</h4>
              <p>{words["活動実績"]}</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
