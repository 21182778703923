const query = (action, params) => {
  const base = process.env.API_ENDPOINT;
  const query = new URLSearchParams(params).toString();
  return window
    .fetch(`${base}${action}?${query}`)
    .then((response) => response.json());
};

export const defaultYear = 2020;

export const fetchCategories = () => {
  return query("categories");
};

export const fetchMinistries = () => {
  return query("ministries");
};

export const fetchMinistryCategories = () => {
  return query("ministryCategories");
};

export const fetchProject = (params) => {
  return query("project", params);
};

export const fetchProjects = (params) => {
  return query("projects", params);
};

export const fetchBudget = (params) => {
  return query("budget", params);
};

export const fetchCategoryCount = (params) => {
  return query("categoryCount", params);
};

export const fetchCategoryExecuted = (params) => {
  return query("categoryExecuted", params);
};

export const fetchMethodCount = (params) => {
  return query("methodCount", params);
};

export const fetchMethodExecuted = (params) => {
  return query("methodExecuted", params);
};

export const fetchMinistryCount = (params) => {
  return query("ministryCount", params);
};

export const fetchMinistryExecuted = (params) => {
  return query("ministryExecuted", params);
};

export const fetchPayee = (params) => {
  return query("payee", params);
};

export const fetchPayees = (params) => {
  return query("payees", params);
};

export const fetchSummary = (params) => {
  return query("summary", params);
};
