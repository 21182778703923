import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";

import imageUrl from "../../images/about-review.png";
import pdf from "../../about-review.pdf";

export class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <Head title="about" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <Link to="/about">JUDGIT!の趣旨</Link>
                </li>
              </ul>
            </nav>
            <div className="content">
              <h2>
                大勢の人が来れば来るほど、政治や行政が良くなるサイト、税金の無駄遣いが減るサイト
              </h2>

              <div style={{ marginBottom: "3rem" }}>
                <p>
                  今や私たちはスマホやパソコンにいくつかのキーワードさえ入れれば、世界中の情報にアクセスでき、ビジネスでも買い物でも調べものでもできる。ところが、大きなブラックボックスがある。政府の予算や事業だ。
                </p>
                <p>
                  国も地方も多くの統計情報を公表している。しかしそれらは、国民が納得するかとか、活用できるかなどを意識して作られていないため、多くの国民も、企業も、メディアも本当に知りたいことがなかなか分からない。もし、これらがキーワード検索できたら、一気に国や地方の状況や行政の中身が見えてくる。
                </p>
                <p>
                  私たちは、政府の情報の中でも核心に位置する「予算」をデータベース化したものに、検索機能をつけて誰でも使えるようにした。
                </p>
              </div>

              <div style={{ marginBottom: "3rem" }}>
                <p>
                  ここで重要なことは、予算の何をデータベース化したかだ。それについて少し説明しておきたい。いわゆる予算書と言われるものではない。従来の予算関連資料には、事業の詳細や具体的な目的は書かれていないからだ。ほとんどの国民はこれを見ても何もわからない。データベース化されたのは「事業シート」とか「行政事業レビューシート」と呼ばれるものだ。
                </p>
                <p>
                  従来、国の予算については、目的や成果について説明をしないままに多くの事業が行われていたのが実態だった。2008年、自民党が行政改革を進めるために党内に作った「無駄撲滅チーム」の一つの班で、日本初の国の事業仕分けを行った。構想日本はそれに協力し、議論の基礎資料とするため対象事業の「事業シート」を初めて作った。
                </p>
                <p>
                  これは、行政機関が行っている事業毎に「何が目的か」「事業の内容」「予算額」「事業予算の支払い先」「どんな成果があったか」など約30項目をフォーマット化して各省の担当課が記入するものだ。
                </p>
                <p>
                  翌年、民主党政権となり、政府による事業仕分けが行われた際も「事業シート」が作られた。その後安倍政権になってから現在に至るまで「行政事業レビューシート」と名を変えて約5,000ある国の事業すべてについて継続して作られている。
                </p>
              </div>

              <div style={{ marginBottom: "3rem" }}>
                <p>
                  「行政事業レビューシート」は2014年以来、内閣官房がエクセルでデータベース化して公表していたが、今回は、そのデータベースに検索機能などをつけ、誰でも利用できるサイトとしてオープンしたものだ。（実はこのJUDGIT！のサイトは、構想日本が2013年に一度開いたのだが、その時は「行政事業レビューシート」そのものを集めたものに留まり、検索はできなかった。）
                </p>
                <p>
                  JUDGIT！では、キーワードを入れることで国が行っている事業を様々な面から検索できるため、例えば「省庁間の事業の重複」「国の事業の受注企業ランキング」なども分かる。ここまで調べられるのは「行政事業レビューシート」という国民に対する説明を目的とする基礎資料が作られているからだ。「行政事業レビューシート」と検索機能の組み合わせは世界に誇れると思う。
                </p>
                <p>
                  近く消費税率が上がる予定だが、税金は少しでも無駄なく使ってほしいものだ。そのためには政治家や官僚に任せきりではなく、できるだけ多くの人がいろんな面からチェックすることが一番だ。
                </p>
                <p>
                  もちろんこのサイトは予算のチェックだけが目的ではない。国の事業を受注したい企業にとっては事業の特徴や競争相手を調べることができる。例えば、省エネやリサイクルなど特定の分野の補助金についても調べられる。国の予算や事業について何かを調べたい人がそれぞれキーワードを入れて検索すればいいのだ。
                </p>
                <p>
                  誰でもが国がしていることについて知りたいことを知り、何かを発見する。それがJUDGIT！だ。国の予算や事業を通して政治・行政が多くの人にとって「自分ごと」になる。政治・行政は、緊張感が出てレベルが上がる。それがJUDGIT！の目指すところだ。
                </p>
              </div>

              <div style={{ marginBottom: "3rem" }}>
                <p>
                  一方で、このデータベースはまだまだ未完成だ。大勢の人に使ってもらって「ここが知りたい」「こんな機能を加えられないか」といった要望をどんどん出してほしい。
                </p>
                <p>
                  国民だれもが、国の事業や予算に直接関われるサイト。大勢の人が来れば来るほど政治・行政が国民に近くなり良くなるサイト。それがこのプロジェクトの旗印だ。
                </p>
              </div>
            </div>
            <div className="content">
              <h2>行政事業レビューとは？</h2>
              <p>
                行政事業レビューとは、国の各府省が行う約5,000のすべての事業について、Plan(計画の立案)
                - Do(事業の実施) - Check(事業の効果の点検) -
                Action(改善)のサイクルが機能するよう、各府省が点検・見直しを行うものです。
              </p>

              <p>構想日本が提唱した「事業仕分け」の３大要素</p>
              <ol>
                <li>国の事業のフォーマット化</li>
                <li>現場に詳しい行政外部の人が、行政の事業をチェック</li>
                <li>1、2の全面公開</li>
              </ol>
              <p>
                これらを各府省に内生化したものが「行政事業レビュー」であり、
                そのうち、①を具現化するものが「行政事業レビューシート」です。
                レビューシートを見れば、事業ごとの「目的」「内容」「成果」「事業予算の支払い先」などが明らかになります。
              </p>

              <img src={imageUrl} />

              <h3>行政事業レビューシートの対象範囲について</h3>
              <p>
                レビューシートにはすべての支出が入っているわけではなく、以下の通り、除外されている国の機関や予算項目があります（政府の方針によるもの）。
              </p>

              <ul>
                <li>
                  除外されている国の機関
                  <ul>
                    <li>国会</li>
                    <li>裁判所</li>
                    <li>会計検査</li>
                    <li>人事院</li>
                  </ul>
                </li>
                <li>
                  {" "}
                  除外されている予算項目
                  <ul>
                    <li>人件費</li>
                    <li>各府省の事務的経費</li>
                    <li>国債費</li>
                    <li>地方交付税交付金など</li>
                  </ul>
                </li>
              </ul>

              <p>詳細はこちら</p>

              <ul>
                <li>
                  <a href={pdf}>
                    行政事業レビューにおける点検の対象外の事業について実施要領別紙（政府資料）
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
