export const startYear = (project, emptyLabel = "不明") => {
  return project.startYear ? `${project.startYear}年` : emptyLabel;
};

export const endYear = (project, emptyLabel = "終了予定なし") => {
  return project.endYear ? `${project.endYear}年` : emptyLabel;
};

export { formatCurrency } from "./formatter";

let count = 1;

export const setLoading = (isLoading) => {
  if (isLoading) {
    count += 1;
  } else {
    count -= 1;
  }
  if (count > 0) {
    document.querySelector(".pageloader.init").classList.add("is-active");
  } else {
    document.querySelector(".pageloader.init").classList.remove("is-active");
  }
};
