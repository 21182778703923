import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../services";

export class PayeeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {
        column: "amount",
        desc: true,
      },
    };
  }

  render() {
    const payees = this.props.payees.map((p) => p);
    if (this.state.order) {
      const { column } = this.state.order;
      payees.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      if (this.state.order.desc) {
        payees.reverse();
      }
    }
    return (
      <table
        className="table is-bordered is-fullwidth"
        style={{ marginBottom: "0.75rem" }}
      >
        <thead>
          <tr>
            <th
              style={{ cursor: "pointer", width: "10%" }}
              onClick={() => {
                this.changeOrder("ministry");
              }}
            >
              府省庁
            </th>
            <th
              style={{ cursor: "pointer", width: "30%" }}
              onClick={() => {
                this.changeOrder("projectName");
              }}
            >
              事業名
            </th>
            <th
              style={{ cursor: "pointer", width: "10%" }}
              onClick={() => {
                this.changeOrder("reportYear");
              }}
            >
              年度
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.changeOrder("description");
              }}
            >
              業務概要
            </th>
            <th
              style={{ cursor: "pointer", width: "10%" }}
              onClick={() => {
                this.changeOrder("amount");
              }}
            >
              支出額（百万円）
            </th>
          </tr>
        </thead>
        <tbody>
          {payees.map((payee, i) => {
            return (
              <tr key={i}>
                <td>
                  <Link to={`/ministries/${payee.ministry}`}>
                    {payee.ministry}
                  </Link>
                </td>
                <td>
                  <Link to={`/projects/${payee.projectId}`}>
                    {payee.projectName}
                  </Link>
                </td>
                <td>{payee.reportYear - 1}</td>
                <td>{payee.description}</td>
                <td className="has-text-right">
                  {formatCurrency(payee.amount)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  changeOrder(column) {
    const order = {
      column,
      desc: false,
    };
    if (this.state.order.column === column) {
      order.desc = !this.state.order.desc;
    }
    this.setState({ order });
  }
}
