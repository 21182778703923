import React from "react";
import { Link } from "react-router-dom";
import { startYear, endYear, formatCurrency } from "../../services";

const minBudget = (project) => {
  const value = Math.min(...project.budget.map(({ amount }) => amount));
  return value < Infinity ? value : "-";
};

const maxBudget = (project) => {
  return Math.max(0, ...project.budget.map(({ amount }) => amount));
};

export class ProjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {
        column: "ministry",
        desc: false,
      },
    };
  }

  render() {
    const { projects } = this.props;
    if (this.state.order) {
      const { column } = this.state.order;
      projects.sort((a, b) => {
        if (column === "budget") {
          const aMin = minBudget(a);
          const bMin = minBudget(b);
          if (aMin === "-" && bMin === "-") {
            return 0;
          }
          if (aMin === "-") {
            return this.state.order.desc ? -1 : 1;
          }
          if (bMin === "-") {
            return this.state.order.desc ? 1 : -1;
          }
          return minBudget(a) - minBudget(b);
        }
        return a[column] < b[column] ? -1 : 1;
      });
      if (this.state.order.desc) {
        projects.reverse();
      }
    }

    return (
      <div style={{ overflowX: "auto" }}>
        <table className="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th
                className="has-text-centered"
                style={{ cursor: "pointer", width: "20%" }}
                onClick={() => {
                  this.changeOrder("ministry");
                }}
              >
                府省庁
              </th>
              <th
                className="has-text-centered"
                style={{ cursor: "pointer", width: "40%" }}
                onClick={() => {
                  this.changeOrder("projectName");
                }}
              >
                事業名
              </th>
              <th
                className="has-text-centered"
                style={{ cursor: "pointer", width: "20%" }}
                onClick={() => {
                  this.changeOrder("startYear");
                }}
              >
                事業期間
              </th>
              <th
                className="has-text-centered"
                style={{ cursor: "pointer", width: "20%" }}
                onClick={() => {
                  this.changeOrder("budget");
                }}
              >
                予算規模
                <br />
                （百万円）
              </th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => {
              return (
                <tr key={project.projectId}>
                  <td>
                    <Link to={`/ministries/${project.ministry}`}>
                      {project.ministry}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/projects/${project.projectId}`}>
                      {project.projectName}
                    </Link>
                  </td>
                  <td className="has-text-right">
                    開始：{startYear(project)}
                    <br />
                    終了：{endYear(project, "予定なし")}
                  </td>
                  <td className="has-text-right">
                    最小：{formatCurrency(minBudget(project))}
                    <br />
                    最大：{formatCurrency(maxBudget(project))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  changeOrder(column) {
    const order = {
      column,
      desc: false,
    };
    if (this.state.order.column === column) {
      order.desc = !this.state.order.desc;
    }
    this.setState({ order });
  }
}
