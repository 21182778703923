import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";

export class ContactPage extends React.Component {
  render() {
    return (
      <div>
        <Head title="contact" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <Link to="/contact">プロジェクト概要</Link>
                </li>
              </ul>
            </nav>
            <div className="content">
              <h2>運営体制</h2>
              <div>
                <ul>
                  <li>政策シンクタンク 構想日本</li>
                  <li>日本大学文理学部情報科学科 尾上洋介研究室</li>
                  <li>Visualizing.JP</li>
                  <li>特定非営利活動法人Tansa</li>
                </ul>
                <p>上記団体及び個人による共同運営を行なっている。</p>
                <h3>事務局</h3>
                <p>JUDGIT!事務局（構想日本内）</p>
                <p>担当：伊藤、田中、永由、秋元</p>
                <p>TEL：03-5275-5607</p>
                <p>Mail：judgit@kosonippon.org</p>
              </div>
              <h2>利用規約</h2>
              <Link to="/terms">利用規約</Link>
              <h2>お問い合わせ</h2>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScbbb-rVHoyv37jdP7vZcf2RJGUVje99La--AlfyF1WEt2RmA/viewform?embedded=true"
                style={{ width: "100%", height: "1100px" }}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              >
                Loading...
              </iframe>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
