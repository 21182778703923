import React from "react";

export class ChartSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { CrossFilter: null };
  }

  componentDidMount() {
    import("./crossfilter").then(({ CrossFilter }) => {
      this.setState({ CrossFilter });
    });
  }

  render() {
    const { CrossFilter } = this.state;
    if (CrossFilter == null) {
      return <p>loading...</p>;
    }
    return <CrossFilter history={this.props.history} />;
  }
}
