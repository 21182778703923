import React from "react";

const Tooltip = ({ children, message }) => {
  return (
    <span
      className="tooltip is-tooltip-right is-tooltip-multiline"
      data-tooltip={message}
    >
      {children}
    </span>
  );
};

export default Tooltip;
