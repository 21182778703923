import React from "react";
import HighCharts from "highcharts";

export const Chart = ({ options }) => {
  const ref = React.createRef();

  React.useEffect(() => {
    const chart = HighCharts.chart(ref.current, options);
    return () => {
      chart.destroy();
    };
  });

  return <div ref={ref} />;
};
