import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { formatCurrency, setLoading } from "../../services";
import { fetchPayee } from "../../services/api";
import { PayeeTable } from "./payee-table";
import { ministries } from "../../constants";

export class PayeeDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      selectedMinistry: "",
      selectedYear: "",
      payees: [],
    };
  }

  componentDidMount() {
    setLoading(true);
    const { corporateNumber } = this.props.match.params;
    fetchPayee({ corporateNumber }).then((payees) => {
      this.setState({
        payees,
      });
      setLoading(false);
    });
  }

  render() {
    const { filter, selectedMinistry, selectedYear, payees } = this.state;

    if (payees.length === 0) {
      return null;
    }

    const names = Array.from(new Set(payees.map(({ name }) => name)));
    const nameCount = new Map(
      names.map((name) => [
        name,
        payees.filter((payee) => payee.name === name).length,
      ])
    );
    names.sort((n1, n2) => nameCount.get(n2) - nameCount.get(n1));
    const name = names.length === 0 ? "" : names[0];

    const filteredPayees = payees.filter((payee) => {
      if (selectedMinistry && payee.ministry !== selectedMinistry) {
        return false;
      }
      if (selectedYear && payee.reportYear - 1 !== selectedYear) {
        return false;
      }
      if (
        filter &&
        !payee.projectName.includes(filter) &&
        !payee.description.includes(filter)
      ) {
        return false;
      }
      return true;
    });

    return (
      <div>
        <Head title={name} />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb" style={{ overflowX: "auto" }}>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li>
                  <Link to="/payees">主要支出先検索</Link>
                </li>
                <li className="is-active">
                  <a>{name}</a>
                </li>
              </ul>
            </nav>
            <div>
              <h3 className="title">{name}</h3>
              <p>※2015〜2019年度</p>
              <div style={{ marginBottom: "0.75rem" }}>
                <div style={{ overflowX: "auto" }}>
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{ marginBottom: "0.75rem" }}
                  >
                    <tbody>
                      <tr>
                        <th style={{ width: "50%" }}>件数</th>
                        <td className="has-text-right" style={{ width: "50%" }}>
                          {payees.length}
                        </td>
                      </tr>
                      <tr>
                        <th>総支出額（百万円）</th>
                        <td className="has-text-right">
                          {formatCurrency(
                            payees
                              .map(({ amount }) => amount)
                              .reduce((a, b) => a + b)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <div className="select is-fullwidth">
                      <select
                        onChange={(event) => {
                          this.setState({
                            selectedMinistry: event.target.value,
                          });
                        }}
                      >
                        <option value="">府省庁</option>
                        {ministries.map((ministry, i) => {
                          return (
                            <option key={i} value={ministry}>
                              {ministry}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="control is-expanded">
                    <div className="select is-fullwidth">
                      <select
                        onChange={(event) => {
                          this.setState({ selectedYear: +event.target.value });
                        }}
                      >
                        <option value="">年度</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      className="input"
                      placeholder="絞り込みキーワード"
                      onChange={(event) => {
                        this.setState({ filter: event.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{ overflowX: "auto" }}>
                <PayeeTable payees={filteredPayees} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
