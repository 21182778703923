export const ministries = [
  "内閣官房",
  "内閣府",
  "個人情報保護委員会",
  "公正取引委員会",
  "警察庁",
  "金融庁",
  "消費者庁",
  "総務省",
  "法務省",
  "外務省",
  "財務省",
  "文部科学省",
  "厚生労働省",
  "農林水産省",
  "経済産業省",
  "国土交通省",
  "環境省",
  "原子力規制委員会",
  "防衛省",
  "復興庁",
  "カジノ管理委員会",
];

export const categories = [
  "地方創生",
  "ＩＴ戦略",
  "国土強靱化施策",
  "観光立国",
  "宇宙開発利用",
  "2020年東京オリパラ",
  "科学技術・イノベーション",
  "沖縄振興",
  "高齢社会対策",
  "地球温暖化対策",
  "医療分野の研究開発関連",
  "子ども・若者育成支援",
  "障害者施策",
  "交通安全対策",
  "男女共同参画",
  "少子化社会対策",
  "犯罪被害者等施策",
  "一億総活躍推進",
  "海洋政策",
  "クールジャパン",
  "ＯＤＡ",
  "知的財産",
  "自殺対策",
  "食育推進",
  "その他",
];

export const methods = [
  "委託・請負",
  "直接実施",
  "補助",
  "交付",
  "貸付",
  "その他",
];

export const words = {
  主要施策:
    "政府が進める主要な政策・施策のこと。（例：地方創生・クールジャパンなど）",
  一般会計:
    "国における会計区分の一つで、租税などの収入を財源として国の基本的・一般的な支出を経理する会計のこと。",
  特別会計:
    "一般会計と区分して経理する特別の会計で、特定の事業を行う場合に特定の資金を保有して、その運用を行う場合などに限って設置される会計のこと。",
  直接実施: "事務や事業などを国が直接行うこと。",
  "委託・請負": "事務や事業などを他の機関や特定の企業などに実施を委ねること。",
  交付:
    "法令または条例、規則などにより、団体あるいは組合などに対して事務や事業を委託すること。（その場合に、報酬として支払うこと。）",
  要求額:
    "各府省庁が事業を実施するのに必要と判断し、算出した額（経費の見積）のこと。",
  当初予算: "当該会計年度の年間予算として当初に成立した予算のこと。",
  補正予算:
    "予算作成後の事情の変更によって、その予算に不足が生じた場合、また、予算の内容を変える必要が生じた場合に、出来上がった予算を変更する予算のこと。",
  前年度から繰越し: "前の会計年度から当該会計年度へ持ち越した金額のこと。",
  翌年度へ繰越し: "当該会計年度から次の会計年度へ持ち越す金額のこと。",
  予備費等:
    "予見し難い予算の不足に充てるための経費で、予算成立後において歳出に計上された既定経費に不足を生じたり、又は新規に経費が必要となった場合、その不足に充てるため、内閣の責任において支出できるもの。",
  成果目標: "事業の目的に照らし、達成すべき成果に関する定量的な目標。",
  成果指標:
    "成果目標として設定した数値の測定基礎となる単位。当該事業がどのような状況になったら「目標の達成」と言えるかの指標。",
  成果実績: "成果指標に基づいて測定した実績値など。",
  活動指標:
    "各事業における事業の箇所数・件数・人数などの事業の定量的な活動内容。",
  活動実績: "活動指標に基づいて測定した実績値など（「何をしたか」）。",
};
