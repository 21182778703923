import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { KeywordSearch } from "./keyword-search";
import { ChartSearch } from "./chart-search";

export class ProjectListPage extends React.Component {
  render() {
    const { hash } = this.props.location;
    return (
      <div>
        <Head title="行政事業検索" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb" style={{ overflowX: "auto" }}>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <a>行政事業検索</a>
                </li>
              </ul>
            </nav>
            <div className="tabs is-centered">
              <ul>
                <li className={hash !== "#chart" ? "is-active" : ""}>
                  <Link to="/projects">キーワードで検索</Link>
                </li>
                <li className={hash === "#chart" ? "is-active" : ""}>
                  <Link to="/projects#chart">グラフで検索</Link>
                </li>
              </ul>
            </div>
            <div>
              {hash === "#chart" ? (
                <ChartSearch history={this.props.history} />
              ) : (
                <KeywordSearch
                  history={this.props.history}
                  location={this.props.location}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
