import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { formatCurrency } from "../../services";
import { fetchPayees } from "../../services/api";

const keywords = [
  "株式会社",
  "合名会社",
  "合資会社",
  "合同会社",
  "有限会社",
  "一般財団法人",
  "公益財団法人",
  "一般社団法人",
  "公益社団法人",
  "学校法人",
  "監査法人",
  "国立大学法人",
  "国立研究開発法人",
  "大学共同利用機関法人",
  "独立行政法人",
  "ＮＰＯ法人",
];

export class PayeeListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      payees: [],
    };
  }

  componentDidMount() {
    this.loadPayees();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.loadPayees();
    }
  }

  render() {
    const { loading, payees } = this.state;

    const params = new URLSearchParams(this.props.location.search);
    return (
      <div>
        <Head title="主要支出先検索" />
        <section className="section">
          <div className="container">
            <nav className="breadcrumb" style={{ overflowX: "auto" }}>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <a>主要支出先検索</a>
                </li>
              </ul>
            </nav>
            <div style={{ marginBottom: "2rem" }}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  this.search();
                }}
              >
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      ref="keyword"
                      className="input is-medium is-rounded"
                      defaultValue={params.get("keyword") || ""}
                      placeholder="検索キーワード"
                      required
                    />
                  </div>
                  <div className="control">
                    <span className="select is-medium is-hidden-touch">
                      <select
                        ref="condition"
                        defaultValue={params.get("condition") || "and"}
                      >
                        <option value="and">AND</option>
                        <option value="or">OR</option>
                      </select>
                    </span>
                  </div>
                  <div className="control">
                    <button
                      className={`button is-medium is-rounded${
                        loading ? " is-loading" : ""
                      }`}
                    >
                      検索
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="tags">
              {keywords.map((keyword) => {
                return (
                  <a
                    key={keyword}
                    className="tag is-info is-inverted is-outlined is-small"
                    onClick={() => {
                      this.refs.keyword.value = keyword;
                      this.props.history.push(`/payees/?keyword=${keyword}`);
                    }}
                  >
                    {keyword}
                  </a>
                );
              })}
            </div>
            <div>
              <div style={{ overflowX: "auto" }}>
                <table className="table is-fullwidth is-hoverable">
                  <thead>
                    <tr>
                      <th
                        style={{ width: "40%" }}
                        className="has-text-centered"
                      >
                        支出先
                      </th>
                      <th
                        style={{ width: "30%" }}
                        className="has-text-centered"
                      >
                        件数
                      </th>
                      <th
                        style={{ width: "30%" }}
                        className="has-text-centered"
                      >
                        総支出額（百万円）
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payees.map(
                      (
                        { name, corporateNumber, totalAmount, totalCount },
                        i
                      ) => {
                        return (
                          <tr key={i}>
                            <th>
                              <Link to={`/payees/${corporateNumber}`}>
                                {name}
                              </Link>
                            </th>
                            <td className="has-text-right">{totalCount}</td>
                            <td className="has-text-right">
                              {formatCurrency(totalAmount)}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  loadPayees() {
    this.setState({
      loading: true,
    });
    const params = new URLSearchParams(this.props.location.search);
    const options = {};
    for (const [k, v] of params) {
      options[k] = v;
    }
    fetchPayees(options).then((payees) => {
      this.setState({
        loading: false,
        payees,
      });
    });
  }

  buildParams() {
    const params = new URLSearchParams();
    params.set("keyword", this.refs.keyword.value);
    params.set("condition", this.refs.condition.value);
    return params;
  }

  search() {
    const params = this.buildParams();
    this.props.history.push(`/payees?${params.toString()}`);
  }
}
