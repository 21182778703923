import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { defaultYear, fetchCategories } from "../../services/api";
import { formatCurrency } from "../../services";

const CategoryTable = ({ categories }) => {
  return (
    <table className="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>支出先</th>
          <th className="has-text-right">執行額（百万円）</th>
        </tr>
      </thead>
      <tbody>
        {categories.map(({ category, executed }, i) => {
          return (
            <tr key={i}>
              <th>
                <Link to={`/categories/${category}`}>{category}</Link>
              </th>
              <td className="has-text-right">{formatCurrency(executed)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export class CategoryListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    fetchCategories().then((data) => {
      if (this.unmounted) {
        return;
      }
      this.setState({
        categories: data,
      });
    });
  }

  render() {
    const { categories } = this.state;
    return (
      <section className="section">
        <Head title="主要施策別検索" />
        <div className="container">
          <nav className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Top</Link>
              </li>
              <li className="is-active">
                <Link to="/categories">主要施策</Link>
              </li>
            </ul>
          </nav>
          <h2 className="title">主要施策別執行額（${defaultYear}年度）</h2>
          <CategoryTable categories={categories} />
        </div>
      </section>
    );
  }
}
