import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import { defaultYear, fetchMinistries } from "../../services/api";
import { formatCurrency } from "../../services";

const MinistryTable = ({ ministries }) => {
  return (
    <table className="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>支出先</th>
          <th className="has-text-right">執行額（百万円）</th>
        </tr>
      </thead>
      <tbody>
        {ministries.map(({ ministry, executed }, i) => {
          return (
            <tr key={i}>
              <th>
                <Link to={`/ministries/${ministry}`}>{ministry}</Link>
              </th>
              <td className="has-text-right">{formatCurrency(executed)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export class MinistryListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ministries: [],
    };
  }

  componentDidMount() {
    fetchMinistries().then((data) => {
      if (this.unmounted) {
        return;
      }
      this.setState({
        ministries: data,
      });
    });
  }

  render() {
    const { ministries } = this.state;
    return (
      <section className="section">
        <Head title="府省庁別検索" />
        <div className="container">
          <nav className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Top</Link>
              </li>
              <li className="is-active">
                <Link to="/ministries">府省庁</Link>
              </li>
            </ul>
          </nav>
          <h2 className="title">主要施策別執行額（{defaultYear}年度）</h2>
          <MinistryTable ministries={ministries} />
        </div>
      </section>
    );
  }
}
