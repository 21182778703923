import React from "react";

export const Words = ({ words, onClick }) => {
  return (
    <div className="tags">
      {words.map((word) => {
        return (
          <a
            key={word}
            className="tag is-info is-inverted is-outlined is-small"
            onClick={() => onClick(word)}
          >
            {word}
          </a>
        );
      })}
    </div>
  );
};
