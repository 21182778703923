import React from "react";
import { Link } from "react-router-dom";
import { ministries, categories } from "../../constants";
import { MinistryCategoryBudgetChart } from "./ministry-category-budget-chart";

const toQueryString = (args) => {
  const params = new URLSearchParams();
  for (const k in args) {
    if (args[k]) {
      params.set(k, args[k]);
    }
  }
  return params.toString();
};

export class PolicyPage extends React.Component {
  render() {
    const params = new URLSearchParams(this.props.location.search);
    const ministry = params.get("ministry") || "";
    const category = params.get("category") || "";

    return (
      <div>
        <section className="section">
          <div className="container">
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li className="is-active">
                  <a>府省庁・主要政策別</a>
                </li>
              </ul>
            </nav>
            <div style={{ marginBottom: "0.75rem" }}>
              <div className="field is-grouped">
                <div className="control">
                  <div className="select">
                    <select
                      ref="ministry"
                      value={ministry}
                      onChange={(e) => this.handleChangeOption(e)}
                    >
                      <option value="" />
                      {ministries.map((ministry) => (
                        <option key={ministry} value={ministry}>
                          {ministry}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="control">
                  <div className="select">
                    <select
                      ref="category"
                      value={category}
                      onChange={(e) => this.handleChangeOption(e)}
                    >
                      <option value="" />
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <MinistryCategoryBudgetChart
                ministry={ministry}
                category={category}
              />
            </div>
            <div className="field">
              <div className="control">
                <Link
                  className="button is-info"
                  to={{
                    pathname: "/projects",
                    search: toQueryString({ ministry, category }),
                  }}
                >
                  事業一覧を表示
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  handleChangeOption() {
    const params = new URLSearchParams();
    if (this.refs.ministry.value) {
      params.set("ministry", this.refs.ministry.value);
    }
    if (this.refs.category.value) {
      params.set("category", this.refs.category.value);
    }
    this.props.history.push(`/policies?${params.toString()}`);
  }
}
